h1 {
    line-height: 2px;
    color: #b1b5b3;
}
h3 {
    line-height: 2px;
    color: #9da19f;
}

.mt-10 {
    padding-top: 10px !important;
}

.logo {
    background-image: url("../../asset/image/gencoder-logo-2.png");
}

.topFixedBanner {
    /*background-color: #0a58ca;*/
    background-image: url("../../asset/image/gencoder_team_image_1.png");
    background-attachment: fixed;
    min-height: 100vh;
    background-size: cover;
    height: 100%;
}
.topBannerOverlay {
    position: absolute;
    min-height: 100vh;
    width: 100%;
    background: rgba(0,0,0,0.8);
    display: block;
}
.topContent {
    margin-top: 15rem;
    height: 100%;
    display: block;
}
.topTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #e1e2e3;
    font-size: 43px;
}
.topSubTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #e1e2e3;
    font-size: 22px;
    padding-top: 10px;
}
.topButton {
    margin-top: 20px !important;
}
@media only screen and (max-width: 992px) {
    .topContent {
        margin-top: 5rem;
        height: 100%;
    }
    .topTitle {
        font-family: Montserrat,'sans-serif';
        font-weight: 700;
        color: #e1e2e3;
        font-size: 22px;
    }
    .topSubTitle {
        font-family: Montserrat,'sans-serif';
        font-weight: 600;
        color: #e1e2e3;
        font-size: 15px;
        padding-top: 10px;
    }
    .topButton {
        margin-top: 15px !important;
    }
}
.aboutSection {
    background: #466393;
    padding-top: 8rem;
    padding-bottom: 8rem;
    display:block;
    position: relative;
}
.aboutContent {

}
.aboutTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #e1e2e3;
    font-size: 35px;
}
.aboutSubTitlePara {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #e1e2e3;
    font-size: 15px;
    padding-top: 20px;
}
.aboutBtn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.05);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: 0.3s;
    text-decoration: none;
}
.aboutBtn i {
    font-size: 14px;
}
.aboutBtn:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    text-decoration: none;
}
.icon-boxes {
    margin-top: 30px;
    font-size: 40px;
    color: #b6bdfc;
    margin-bottom: 10px;
}
.aboutSubjectTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #e1e2e3;
    font-size: 25px;
}
.aboutSubjectPara {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #e1e2e3;
    font-size: 15px;
    padding-top: 0px;
}
.navBarBackground {
    -webkit-transition: background-color 400ms linear;
    -moz-transition: background-color 400ms linear;
    -o-transition: background-color 400ms linear;
    transition: background-color 400ms linear;
    background: rgba(225, 226, 227, 0) !important;
}
.navBarBackgroundScroll {
    -webkit-transition: background-color 400ms linear;
    -moz-transition: background-color 400ms linear;
    -o-transition: background-color 400ms linear;
    transition: background-color 400ms linear;
    background: rgba(255, 255, 255, 1) !important;
}
.navItem {
    text-decoration: none !important;

    color: #DDDDDD;
    transition: 0.3s;
    font-size: 14px;
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
}
.navItem:hover {
    color: #f5c2c7;
}
a.navItem.active {
    color: yellow;
}
.navItemScroll {
    text-decoration: none !important;
    display: block;
    color: #505050;
    transition: 0.3s;
    font-size: 14px;
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
}
.languageSection {
    padding: 100px 0px 50px 0px;
    background-color: #e1e1e1 !important;
    text-align: center;
}
.languageImage {
    box-shadow: 0px 0px 30px rgba(200,196,219,.3);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 50px 50px 50px 50px;
    margin: 2% 0;
    width: 100%;
}
.featuresContent {
    padding: 50px 0px 50px 0px;
}
.featuresTitle{
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}
.featuresTitle::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #aabbd7;
    bottom: 0;
    left: calc(50% - 25px);
}
.featuresTitle::after {
    background: rgba(170, 187, 215, 1);
}
.featuresPara {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 50px;
}
.featuresMainContent {
    padding: 0px;
}
.featuresMainContentArea {
    padding: 0px 0px 100px 0px;
    margin: 0px !important;
}
.websiteFeatureImage {
    width: 100%;
}
.softwareFeatureImage {
    width: 100%;
    padding-top: 50px;
}
.domainFeatureImage {
    width: 100%;
}
.hostingFeatureImage {
    width: 100%;
    padding-top: 30px;
}
.featuresSubTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 25px;
    margin-top: 10px;
    padding-top: 10px;
    line-height: 1.3;
}
.featuresSubPara {
    font-family: Montserrat,'sans-serif';
    font-size: 15px;
    font-style: italic;
    padding-top: 5px;
}
.featuresUL {
    list-style: none;
    padding: 0;
}
.featuresLineIcon {
    font-size: 20px;
}
.featuresLine {

}
.servicesSection {
    background: #2D405F;
    padding: 50px 0px;
}
.servicesTitle{
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #e1e2e3;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}
.servicesTitle::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: RED;/*#aabbd7*/
    bottom: 0;
    left: calc(50% - 25px);
}
.servicesTitle::after {
    background: rgba(170, 187, 215, 1);
}
.servicesPara {
    font-family: Montserrat,'sans-serif';
    color: #e1e2e3;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 50px;
}
.servicesContent {
    padding: 30px 0px;
}
.servicesIconBox {
    padding: 50px 10px;
    position: relative;
    overflow: hidden;
    background: #fff;
    transition: all 0.3s;
    border-radius: 5px;
    text-align: center;
}

.servicesIconBox:hover {
    transform: scale(1.08);
}

.servicesIconBox {
    margin-top: 10px;
}

.servicesIconBox i {
    font-size: 40px;
    color: #b6bdfc;
    margin-bottom: 10px;
}

.servicesIconBox h4 {
    font-family: Montserrat,'sans-serif';
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.servicesIconBox p {
    font-family: Montserrat,'sans-serif';
    font-size: 15px;
}
.servicesIconArea {
    margin-bottom: 35px;
    margin-top: 15px;
}

.servicesIcon {
    font-size: 40px;
    line-height: 1;
    color: #2d405f;
    background: #eff2f8;
    padding: 10px 20px 20px 20px;
    border-radius: 50px;
    transition: all 0.3s;
}
.servicesBoxTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    display: block;
}

.servicesBoxTitle a {
    font-family: Montserrat,'sans-serif';
    color: #2d405f;
    transition: 0.3s;
    text-decoration: none;
}

.servicesBoxTitle a:hover {
    color: #4e6fa4;
}
.servicesBoxDescription {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
}
.portfolioSection {
    padding: 60px;
}
.portfolioTitle{
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}
.portfolioTitle::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: RED;/*#aabbd7*/
    bottom: 0;
    left: calc(50% - 25px);
}
.portfolioTitle::after {
    background: rgba(170, 187, 215, 1);
}
.portfolioPara {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 50px;
}
.projectCard {
    border-radius: 2px;
    border: none !important;
    margin: 2px 2px 30px 2px;
    /*box-shadow: 0px 0px 4px 0px rgba(0,125,230,0.5);*/
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.projectCard:hover {
    /*box-shadow: 0px 0px 4px 0px rgba(45,64,95,0.2);*/
    box-shadow: 0 4px 8px 0 rgba(54, 64, 95, 0.2), 0 6px 20px 0 rgba(54, 64, 95, 0.19);
    transform: scale(1.008);
    transition: all .2s ease-out;
    overflow: hidden;
}
.projectCardTitle {
    font-family: Montserrat,'sans-serif';
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
}
.projectCardPara {
    font-family: Montserrat,'sans-serif';
    font-size: 15px;
}
.projectVisit {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #2d405f !important;
    border-color: #35465f !important;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s
}
.projectVisit:hover {
    background-color: #35465f !important;
    border-color: #2d405f !important;
    color: #DDDDDD;
    text-decoration: none;
    font-weight: 400;
}
.testmonialSection {
    padding: 50px 50px 80px 50px;
    background-color: #f6f8fb;
}
.testmonialTitle{
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}
.testmonialTitle::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: RED;/*#aabbd7*/
    bottom: 0;
    left: calc(50% - 25px);
}
.testmonialTitle::after {
    background: rgba(170, 187, 215, 1);
}
.testmonialPara {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 50px;
}
.testmonialContent {

}
.testmonialCircleImage {
    margin: 0px auto;
    width: 100px;
    height: 100px;
    border: 1px solid #0073E6;
    border-radius: 50%;
}
.testmonialPeopleName {
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 23px;
    padding-top: 5px;
    line-height: 1.3;
}
.testmonialPeopleDesig {
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 18px;
    padding-bottom: 10px;
}
.testmonialSubPara {

}
blockquote {
    border:none;
    font-family: Montserrat,'sans-serif';
    margin-bottom:-30px;
    position: relative;
    margin: 0 auto;
    padding-left:2.5em;
    padding-right:2.0em;
}

blockquote p {
    font-size:16px;
    font-style: italic;
}

blockquote p:before {
    content: "\201C";
    font-weight: bold;
    font-size:80px;
    color:#273C51;
    position: absolute;
    top: -0.6em;
    left: 0;
    font-family: Montserrat,'sans-serif';
}
blockquote p:after {
    content: "\201D";
    font-weight: bold;
    font-size:80px;
    color:#273C51;
    position: absolute;
    bottom: -0.9em;
    right: 15px;
    font-family: Montserrat,'sans-serif';
}
.teamSection {
    padding: 50px;
}
.teamTitle{
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}
.teamTitle::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: RED;/*#aabbd7*/
    bottom: 0;
    left: calc(50% - 25px);
}
.teamTitle::after {
    background: rgba(170, 187, 215, 1);
}
.teamPara {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 50px;
}
.teamMember {
    text-align: center;
    margin-bottom: 20px;
}
.teamMember img {
    vertical-align: middle;
    border-style: none;
    box-shadow: 0 0 5px 5px  rgb(0 0 0 / 0.2);
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.teamMemberTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 20px;
    text-align: center;
    padding: 10px 0px 0px 0px;
    margin-bottom: 0px !important;
}
.teamMemberPara {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
}
.contactSection {
    padding: 50px 0px 80px 0px;
    background-color: #f6f8fb;
}
.contactTitle{
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}
.contactTitle::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: RED;/*#aabbd7*/
    bottom: 0;
    left: calc(50% - 25px);
}
.contactTitle::after {
    background: rgba(170, 187, 215, 1);
}
.contactPara {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 50px;
}
.contactLineIcon {
    font-size: 30px;
    text-align: center;
    display: block;
}
.officeAddress {
    background-color: #FFFFFF;
}
.officeAddressBox {
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216,.6);
    padding: 20px 0 30px 0;
}
.contactOfficeAddressTitle {
    font-family: Montserrat,'sans-serif';
    color: #2d405f;
    font-size: 25px;
    padding: 10px 0px;
    text-align: center;
    display: block;
}
.officeEmail {
    background-color: #FFFFFF;
}
.officeEmailBox {
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216,.6);
    padding: 20px 0px 30px 0;
    margin-top: 10px;
}
.contactOfficeEmailTitle {
    font-family: Montserrat,'sans-serif';
    color: #2d405f;
    font-size: 25px;
    padding: 10px 0px;
    text-align: center;
    display: block;
}
.officePhone {
    background-color: #FFFFFF;
}
.officePhoneBox {
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216,.6);
    padding: 20px 0px 30px 0;
    margin-top: 10px;
}
.contactOfficePhoneTitle {
    font-family: Montserrat,'sans-serif';
    color: #2d405f;
    font-size: 25px;
    padding: 10px 0px;
    text-align: center;
    display: block;
}
.officeContactForm {
    background-color: #FFFFFF;
}
.officeContactFormBox {
    box-shadow: 0 0 30px rgba(214, 215, 216,.6);
    padding: 30px 30px 30px 30px;
}
.footerSection {
    box-shadow: 0 0 4px 0 rgba(0,115,230,0.2);
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.footerGenCoderTitle {
    font-family: Montserrat,'sans-serif';
    color: #2d405f;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
}
.footerTitle {
    font-family: Montserrat,'sans-serif';
    color: #2d405f;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2;
}
.footerPara {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.footerUL {
    list-style: none;
    padding: 0;
}
.footerLineIcon {
    font-size: 15px;
}
.footerUL li.footerContentLi {
    padding: 3px 0px;
}
.footerUL li a{
    font-family: Montserrat,'sans-serif';
    font-size: 15px;
    text-decoration: none;
    color: #444444;
    padding:5px 0px;
}
.footerUL li a:hover{
    font-family: Montserrat,'sans-serif';
    font-size: 15px;
    text-decoration: none;
    color: #7887eb;
}
.social-links a {
    font-size: 18px;
    display: inline-block;
    background: #e7eafe;
    color: #2d405f;
    line-height: 1;
    padding: 8px 0px 32px 0px;
    margin-right: 7px;
    border-radius: 5px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}
.social-links a:hover {
    background: #2d405f;
    color: #fff;
    text-decoration: none;
}
.footerLineIcon {
    font-size: 20px;
}
.footerCopyrightSection {
    font-family: Montserrat,'sans-serif';
    color: #444444;
    font-size: 18px;
    background: #f1f3ff;
    box-shadow: 0px 2px 15px rgba(0,0,0,0.5);
    padding: 30px 0px;
}
.footerCopyrightContent a {
    text-decoration: none;
    color: #2d405f;
}
.btn-primary {
    color: #DDDDDD !important;
    background-color: #2d405f !important;
    border-color: #35465f !important;
    padding: 10px 40px !important;
}
.btn-primary:hover {
    color: #fff;
    background-color: #35465f !important;
    border-color: #2d405f !important;
    font-weight: 600;
}
/* PAGE TOP PART START */
.pageTopFixedBanner {
    /*background-color: #0a58ca;*/
    background-image: url("../../asset/image/topPageBanner.png");
    min-height: 200px;
    width: 100%;
    background-repeat: no-repeat;
}
.pageTopBannerOverlay {
    position: absolute;
    min-height: 200px;
    height: 200px;
    width: 100%;
    background: rgba(0,0,0,0.75);
    display: block;
}
.pageTopContent {
    margin-top: 5rem;
    height: 100%;
    display: block;
}
.pageTopSubTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #e1e2e3;
    font-size: 22px;
    padding-top: 10px;
}
/* PAGE TOP PART END */

/* ABOUT PAGE START */
.aboutPageContent {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display:block;
}
.aboutPageTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #2d405f;
    font-size: 25px;
}
.aboutPageSubTitlePara {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #444444;
    font-size: 15px;
    padding-top: 20px;
}
/* ABOUT PAGE START */

/* CAREER PAGE START */
.careerPageTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 700;
    color: #2d405f;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 30px;
    position: relative;
}
.careerPageTitle::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: RED;/*#aabbd7*/
    bottom: 0;
    left: calc(50% - 25px);
}
.careerPageTitle::after {
    background: rgba(170, 187, 215, 1);
}
.careerPageTitlePara {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #444444;
    font-size: 15px;
    text-align: center;
}
.jobTitle {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    color: #444444;
    font-size: 25px;
    padding-top: 30px;
}
.careerImage {
    box-shadow: 0px 2px 15px rgba(0,0,0,0.5);
    margin-top: 50px;
    margin-bottom: 80px;
}
/* CAREER PAGE END */




